import React from "react"
import { graphql } from "gatsby"

import { QueryFragments } from "../GraphQl/queryFragments" // eslint-disable-line

import { PageMeta } from "../components/pageMeta"
import BlockZone from "~blockZone"
import SomedayIcon from '../svg/someday'


const HomePage = ({ data: { page } }) => {
  const { meta, pageBlocks } = page || {}
  return (
    <>
      <PageMeta {...meta} />
      <SomedayIcon />
      {pageBlocks && <BlockZone {...pageBlocks} />}
    </>
  )
}

export default HomePage

export const pageQuery = graphql`
  query HomePageQuery {
    page: sanityHomePage {
      meta {
        ...MetaCardFields
      }
      pageBlocks {
        ...BlockZone
      }
    }
  }
`
